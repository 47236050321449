// Variables
@import "inc/variables";


.cra-alert {
    width: 100%;
    padding: 0px;
    margin: 10px 0;
    position: relative;
    list-style-position: outside;

    ul {
        list-style-position: outside;
        margin: 0;
        padding: 0;

        li {
            list-style: outside none none;
            padding: 0px;
            text-align: center;
            margin-bottom: 8px;
            padding: 10px;
        }        

        li:last-child {
            margin-bottom: 0px !important;
        }
    }
}

.cra-alert-danger {
    background-color: #ff5652;
    color: rgba(255, 255, 255, 0.85);
}

.cra-alert-success {
    background-color: #32c787;
    color: rgba(255, 255, 255, 0.65);
}

.cra-alert-input-up {
    margin-bottom: 26px;
}

#modalClose_message {
    display: none;
}

.block-list span {
    display: none;
}

.close span {
    display: inline;
}

.help-block {
    position: absolute;
}

.iradio .help-block {
    position: relative;
}

#data-table_wrapper .custom-wrapper {
    overflow-x: auto;
    width: 100%;
}

.dropzone {
    .dz-preview {
        max-width: 96%;
    }    

    .dz-custom-control {
        label {
            color: #707070;
        }            
    }
}

.triggers {
    display: none;
}

.colorpicker {
    z-index: 2500 !important;
}

#cssDropzone {
    .dz-size {
        display: none;
    }
    .dz-filename {
        margin-top: 2rem;
    }
}

.card-block__title--inline {
    font-size: 1.1rem;
    line-height: 3.2rem;
    color: #676767;
    text-align: right;
    font-weight: normal;
}

.creatives-preview {
    display: inline-block;
    position: relative;
    margin: 10px;
    border-radius: 2px;
    border: 3px solid $white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    img {
        max-width: 300px;
        max-height: 300px;
    }
}

.creatives-detail img {
    max-width: 300px;
    max-height: 300px;
}

label.required:after {
    content:" *";
    color: $red;
}

.select2-container {
    //z-index: 1050;
}